import { type ReactNode, useContext, useMemo, useState } from 'react';
import React from 'react';

type MuteControlState = {
  muted: boolean;
  setMuted: (next: boolean) => void;
};

const Context = React.createContext<MuteControlState | null>(null);

export function useGamePackMuted(): [boolean, (next: boolean) => void] {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('MuteControlProvider is not in the tree!');
  }
  return [ctx.muted, ctx.setMuted];
}

export function GamePackPreviewMuteControlProvider(props: {
  children?: ReactNode;
}) {
  const [muted, setMuted] = useState(true);

  const ctxValue = useMemo(() => ({ muted, setMuted }), [muted, setMuted]);

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
}
